export const types = {
  GET_LIST_CONTENTS_REQUEST: 'contents/GET_LIST_CONTENTS_REQUEST',
  GET_LIST_CONTENTS_SUCCESS: 'contents/GET_LIST_CONTENTS_SUCCESS',
  GET_LIST_CONTENTS_FAILED: 'contents/GET_LIST_CONTENTS_FAILED',

  GET_CONTENTS_OVERVIEW_REQUEST: 'contents/GET_CONTENTS_OVERVIEW_REQUEST',
  GET_CONTENTS_OVERVIEW_SUCCESS: 'contents/GET_CONTENTS_OVERVIEW_SUCCESS',
  GET_CONTENTS_OVERVIEW_FAILED: 'contents/GET_CONTENTS_OVERVIEW_FAILED',

  GET_DETAIL_CONTENT_REQUEST: 'contents/GET_DETAIL_CONTENT_REQUEST',
  GET_DETAIL_CONTENT_SUCCESS: 'contents/GET_DETAIL_CONTENT_SUCCESS',
  GET_DETAIL_CONTENT_FAILED: 'contents/GET_DETAIL_CONTENT_FAILED',

  GET_DETAIL_CONTENT_REPORTS_REQUEST: 'contents/GET_DETAIL_CONTENT_REPORTS_REQUEST',
  GET_DETAIL_CONTENT_REPORTS_SUCCESS: 'contents/GET_DETAIL_CONTENT_REPORTS_SUCCESS',
  GET_DETAIL_CONTENT_REPORTS_FAILED: 'contents/GET_DETAIL_CONTENT_REPORTS_FAILED',

  GET_DETAIL_CONTENT_ATTEND_EVENT_REQUEST: 'contents/GET_DETAIL_CONTENT_ATTEND_EVENT_REQUEST',
  GET_DETAIL_CONTENT_ATTEND_EVENT_SUCCESS: 'contents/GET_DETAIL_CONTENT_ATTEND_EVENT_SUCCESS',
  GET_DETAIL_CONTENT_ATTEND_EVENT_FAILED: 'contents/GET_DETAIL_CONTENT_ATTEND_EVENT_FAILED',
}
