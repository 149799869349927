import { produce } from 'immer'

import { types } from './constants'

export const initialState = {
  list: {
    records: false,
    isLoading: true,
    total: 0,
  },
  overview: {
    total_contents: 0,
    total_selling: 0,
    total_services: 0,
    total_events: 0,
  },
  // store for table detail content
  table: {
    attendEvent: {
      total: 0,
      records: [],
    },
    reports: {
      total: 0,
      records: [],
    },
    isLoading: true,
  },
  detail: {
    data: {},
    isLoading: true,
  },
  isLoading: true,
}

const contentsReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case types.GET_CONTENTS_OVERVIEW_REQUEST:
        draft.isLoading = true
        break
      case types.GET_LIST_CONTENTS_REQUEST:
        draft.list.isLoading = true
        break
      case types.GET_LIST_CONTENTS_SUCCESS:
        const { data } = payload
        draft.isLoading = false
        draft.list.isLoading = false
        draft.list.records = data.records
        draft.list.total = data.total
        break
      case types.GET_LIST_CONTENTS_FAILED:
        draft.list.isLoading = false
        draft.isLoading = false
        break
      case types.GET_CONTENTS_OVERVIEW_SUCCESS:
        draft.isLoading = false
        draft.overview.total_contents = payload.total_contents
        draft.overview.total_selling = payload.total_selling
        draft.overview.total_services = payload.total_services
        draft.overview.total_events = payload.total_events
        break
      case types.GET_DETAIL_CONTENT_REQUEST:
        draft.detail.isLoading = true
        break
      case types.GET_DETAIL_CONTENT_SUCCESS:
        draft.detail.isLoading = false
        draft.detail.data = payload
        break
      case types.GET_DETAIL_CONTENT_FAILED:
        draft.detail.isLoading = false
        break
      case types.GET_DETAIL_CONTENT_REPORTS_REQUEST:
      case types.GET_DETAIL_CONTENT_ATTEND_EVENT_REQUEST:
        draft.table.isLoading = true
        break
      case types.GET_DETAIL_CONTENT_REPORTS_SUCCESS:
        draft.table.isLoading = false
        draft.table.reports.total = payload.data.total
        draft.table.reports.records = payload.data.records
        break
      case types.GET_DETAIL_CONTENT_ATTEND_EVENT_SUCCESS:
        draft.table.isLoading = false
        draft.table.attendEvent.total = payload.data.total
        draft.table.attendEvent.records = payload.data.records
        break
      default:
        break
    }
  })

export default contentsReducer
