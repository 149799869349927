import { buildUrlWithQueryStrings } from 'utils'
import axiosClient from 'utils/axios'

const authBaseUrl = '/admin/posts'
// params = {sort_by, limit, page, sort_direction,cursor,search,account_type,report_health}
export const getListContents = (params) => {
  return axiosClient.get(buildUrlWithQueryStrings(authBaseUrl, params))
}

export const getContentsOverview = (params) => {
  return axiosClient.get(
    buildUrlWithQueryStrings(`${authBaseUrl}/overview-statistic`, params)
  )
}

export const getDetailContent = (id) => {
  return axiosClient.get(`${authBaseUrl}/${id}`)
}

export const getReports = (params) => {
  return axiosClient.get(buildUrlWithQueryStrings('/reports', params))
}

export const getPlanningToAttend = (id, params) => {
  return axiosClient.get(
    buildUrlWithQueryStrings(`posts/${id}/planning-to-attend-event`, params)
  )
}